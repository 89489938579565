<template>
  <div
    class="pt-2 page-header align-items-start min-vh-20 pb-11 border-radius-lg"
   
  >
    <span class="mask opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-5">
          <h1 class=" text-black">Vendor Registration!</h1>
          <p class="text-black text-lead">
            Use these form to create new account.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-7 col-lg-7 col-md-9">
        <div class="card z-index-0">
          <div class="card-body">
            <form role="form" id="register_form" @submit.prevent="register">
              <div class="row">
              <div class="mb-1 col-md-6">
                <label>First Name:
                  <span class="text-primary">*
                  </span>
                </label>
                <soft-input
                  name="first_name"
                  id="first_name"
                  type="text"
                  placeholder="First Name"
                  aria-label="Name"
                />
                <em class="text-primary text-sm" v-if="!formData.first_name.trim() && validation_fail">first name is required</em>
              </div>
              <div class="mb-1 col-md-6">
                <label>Last Name:
                  <span class="text-primary">*
                  </span>
                </label>
                <soft-input
                  name="last_name"
                  id="last_name"
                  type="text"
                  placeholder="Last Name"
                  aria-label="Last Name"
                />
                <em class="text-primary text-sm" v-if="!formData.last_name.trim() && validation_fail">last name is required</em>
                  
              </div>
              <div class="mb-1 col-md-6">
                <label>Company Name:
                  <span class="text-primary">*
                  </span>
                </label>
                <soft-input
                  name="company_name"
                  id="company_name"
                  type="text"
                  placeholder="Company Name"
                  aria-label="company_name"
                />
                <em class="text-primary text-sm" v-if="!formData.company_name.trim() && validation_fail">Company Name is required</em>
              </div>
              <div class="mb-1 col-md-6">
                <label>Mobile:
                  <span class="text-primary">*
                  </span>
                </label>
                <soft-input
                  name="mobile"
                  id="mobile"
                  type="text"
                  placeholder="mobile number"
                  aria-label="mobile"
                />
                <em class="text-primary text-sm" v-if="!formData.mobile.trim() && validation_fail">mobile number is required</em>
              </div>
              <div class="mb-1 col-md-6">
                <label>Phone:
                  <span class="text-primary">*
                  </span>
                </label>
                <soft-input
                  name="phone"
                  id="phone"
                  type="text"
                  placeholder="Phone Number"
                  aria-label="phone"
                />
                <em class="text-primary text-sm" v-if="!formData.phone.trim() && validation_fail">Phone Number is required</em>
              </div>
              <div class="mb-1 col-md-6">
                <label>Address 1:
                  <span class="text-primary">*
                  </span>
                </label>
                <soft-input
                  name="address_1"
                  id="address_1"
                  type="text"
                  placeholder="Enter Location"
                  aria-label="address_1"
                />
                <em class="text-primary text-sm" v-if="!formData.address_1.trim() && validation_fail">Location is required</em>
              </div>
              <div class="mb-1 col-md-6">
                <label>Address 2:
                </label>
                <soft-input
                  name="address_2"
                  id="address_2"
                  type="text"
                  placeholder=""
                  aria-label="address_2"
                />
              </div>
              <div class="mb-1 col-md-6">
                <label>City:
                  <span class="text-primary">*
                  </span>
                </label>
                <soft-input
                  name="city"
                  id="city"
                  type="text"
                  placeholder="City"
                  aria-label="city"
                />
                <em class="text-primary text-sm" v-if="!formData.city.trim() && validation_fail">City is required</em>
              </div>
              <div class="mb-1 col-md-6">
                <label>State:
                  <span class="text-primary">*
                  </span>
                </label>
                <soft-input
                  name="state"
                  id="state"
                  type="text"
                  placeholder="state"
                  aria-label="state"
                />
                <em class="text-primary text-sm" v-if="!formData.state.trim() && validation_fail">State is required</em>
              </div>
              <div class="mb-3 col-md-6">
                <label>ZIP Code:
                  <span class="text-primary">*
                  </span>
                </label>
                <soft-input
                  name="zipcode"
                  id="zipcode"
                  type="text"
                  placeholder="ZIP Code"
                  aria-label="zipcode"
                />
                <em class="text-primary text-sm" v-if="!formData.zipcode.trim() && validation_fail">ZIP Code is required</em>
              </div>
              <div class="mb-1">
                <label>Email:
                  <span class="text-primary">*
                  </span>
                </label>
                <soft-input
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Email Address"
                  aria-label="email"
                />
                <em class="text-primary text-sm" v-if="!formData.email.trim() && validation_fail">email is required</em>
              </div>
              <div class="mb-1 col-md-6">
                <label>Password:
                  <span class="text-primary">*
                  </span>
                </label>
                <soft-input
                  name="password"
                  id="password"
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                />
                <em class="text-primary text-sm" v-if="!formData.password.trim() && validation_fail">password is required<br></em>
                <em class="text-primary text-sm" v-if="!password_length && validation_fail">password must be at least 8 characters.</em>
              </div>
              <div class="mb-1 col-md-6">
                <label>Confirm Password:
                  <span class="text-primary">*
                  </span>
                </label>
                <soft-input
                  name="c_password"
                  id="c_password"
                  type="password"
                  placeholder="Confirm Password"
                  aria-label="Confirm Password"
                />
                <em class="text-primary text-sm" v-if="!formData.c_password.trim() && validation_fail">confirm password is required<br></em>
                <em class="text-primary text-sm" v-if="!isPasswordsMatch">password did not match</em>
              </div>
              <soft-checkbox
                id="flexCheckDefault"
                name="flexCheckDefault"
                class="font-weight-light"
                checked
              >
                I agree the
                <a href="javascript:;" class="text-dark font-weight-bolder"
                  >Terms and Conditions</a
                >
              </soft-checkbox>

              <div class="text-center">
                <soft-button
                  color="dark"
                  full-width
                  variant="gradient"
                  class="my-4 mb-2"
                  >Sign up</soft-button
                >
              </div>
              <p class="text-sm mt-3 mb-0">
                Already have an account?
                <router-link
                  :to="{ name: 'Sign In' }"
                  class="text-dark font-weight-bolder"
                >
                  Sign in
                </router-link>
              </p>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { mapMutations } from "vuex";

export default {
  name: "SignupBasic",
  components: {
    SoftInput,
    SoftCheckbox,
    SoftButton,
  },
  data() {
    return {
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        mobile: '',
        phone: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zipcode: '',
        password: '',
        c_password: '',
        flexCheckDefault: false
      },
      validation_fail: false,
      isPasswordsMatch: true,
      password_length: true
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  computed: {
    formValid() {
      const { first_name, last_name, email, company_name, mobile, phone, address_1, city, state, password, c_password } = this.formData;
      return first_name.trim() && last_name.trim() && email.trim() && company_name.trim() && mobile.trim() && phone.trim() && address_1.trim() && city.trim() && state.trim() && password.trim() && c_password.trim();
    },
    passwordsMatch() {
      return this.formData.password === this.formData.c_password;
    },
    passwordValidLength() {
      return this.formData.password.length >= 7;
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    updateFormData() {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = $(`#${key}`).val().trim();
      });
    },
    validateForm() {
      this.isPasswordsMatch = this.passwordsMatch;
      this.password_length = this.passwordValidLength;
      return this.formValid && this.isPasswordsMatch && this.password_length;
    },
    async register() {
      this.updateFormData();
      this.validation_fail = !this.validateForm();
      if (this.validation_fail) {
        Swal.fire("Error!", "Please complete the registration form required fields.", "error");
        return;
      }
      const loadingSpinner = Swal.fire({
        title: 'Please wait...',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/create_vendor_account`, this.formData);
        if (response.data.status === 200) {
          this.token = response.data.token;
          this.isModalOpen = true;
          Swal.fire({
            title: 'Success!',
            text: 'Account created successfully.',
            icon: 'success',
            allowOutsideClick: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.value) {
              this.$router.push('/sign-in');
            }
          });
        } else {
          Swal.fire("Error!", response.data.message, "error");
        }
      } catch (error) {
        console.error('Backend error:', error);
        Swal.fire("Error!", "Failed to process registration.", "error");
      } finally {
        loadingSpinner.close();
      }
    }
  },
};
</script>

