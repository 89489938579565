<template>
    <div v-if="isOpen" class="modal-overlay">
        <div class="modal-container modal-lg">
            <div class="col-12 text-end">
                <soft-button color="danger" size="sm" @click="closeModal">X</soft-button>
            </div>
            <div class="modal-header">Invoice Upload Form</div>
            <div class="modal-body">
                <form @submit.prevent="submit_invoice">
                    <label>Description</label>
                    <soft-text-area id="description" type="text" placeholder="Description of the Invoice"
                        name="description" size="lg" @input="updateFormData('description', $event.target.value)" />
                    
                    <label>Date Purchased</label>
                    <soft-input id="date_purchased" type="date" name="date_purchased" size="lg"
                        @input="updateFormData('date_purchased', $event.target.value)" />
                    
                    <label>Client's Name</label>
                    <soft-input id="client_name" type="text" name="client_name" size="lg"
                        @input="updateFormData('client_name', $event.target.value)" />
                    
                    <label>Price</label>
                    <soft-input id="invoice_price" type="text" name="invoice_price" size="lg"
                        @input="updateFormData('invoice_price', $event.target.value)" />
                    
                    <label>Invoice File</label>
                    <soft-input id="invoice_file" type="file" name="invoice_file" size="lg"
                        @change="handleFileChange" />
                    
                    <soft-button color="success" size="lg" type="button" fullWidth="true" @click="submit_invoice">
                        Submit
                    </soft-button>
                </form>
            </div>
        </div>
    </div>
</template>

  
  
  
  <script>
  import SoftInput from "@/components/SoftInput.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import SoftTextArea from "@/components/SoftTextarea.vue";

  import Swal from 'sweetalert2';
  import axios from 'axios';
  import alertify from 'alertifyjs';
  export default {
    data() {
        return {
        formData: {
            description: '',
            date_purchased: '',
            client_name: '',
            invoice_price: ''
        }
        };
    },
    props: {
      isOpen: Boolean,
      title: String,
    },
    components:
    {
        SoftInput,
        SoftButton,
        SoftTextArea,

    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      updateFormData(field, value) {
            this.formData[field] = value;
        },
      handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            this.formData.invoice_file = file;
        }
        },
        async submit_invoice() {
      let data = new FormData();
      data.append('description', this.formData.description);
      data.append('date_purchased', this.formData.date_purchased);
      data.append('client_name', this.formData.client_name);
      data.append('invoice_price', this.formData.invoice_price);
      if (this.formData.invoice_file) {
        data.append('invoice_file', this.formData.invoice_file);
      }

      const token = localStorage.getItem('token');
      try {
            Swal.fire({
                title: 'Uploading...',
                text: 'Please wait',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/upload_invoice`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        Swal.close();

        if (response.data.status == 200) {

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          });
          this.closeModal();
        } else {
          alertify.error(response.data.message);
        }
      } catch (error) {
        console.error('Upload Error:', error);
        alertify.error('Failed to upload invoice.');
      }
    },

    },
  };
  </script>
  
  <style scoped>
  /* Add your styling for the modal */
  .modal-overlay {
    /* Overlay styles */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-container {
    /* Modal styles */
    background: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1050;
  }
  
  .modal-header {
    /* Header styles */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-header h3 {
    margin: 0;
  }
  
  .modal-header button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .modal-lg {
  width: 80%; /* Adjust the width as needed */
}
.alertify {
  z-index: 10001; 
}
.ajs-error {
  z-index: 10002; 
}

  </style>
  