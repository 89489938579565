<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-12 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    Welcome back
                  </h3>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form id="login_form" role="form" class="text-start" @submit.prevent="login">
                    <label>Email</label>
                    <soft-input
                      id="email"
                      type="text"
                      placeholder="Email"
                      name="email"
                    
                    />
                    <label>Password</label>
                    <soft-input
                      id="password"
                      type="password"
                      placeholder="Password"
                      name="password"
                    />
                    <a href="/forgot_password"
                        class="my-4 mb-2"
                        variant="gradient"
                        color="info"
                        type="button"
                        full-width
                        >Forgot Password?
                    </a>

                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        full-width
                        >Sign in
                      </soft-button>
                      <soft-button
                        class="my-4 mb-2"
                        variant="fix"
                        color="info"
                        type="button"
                        full-width
                        :to="{ name: 'Sign Up' }"
                        >Sign Up
                      </soft-button>
                    </div>
                  </form>
                </div>
         
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <otp-modal :token="token" :is-open="isModalOpen" @close="closeModal"  />
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import axios from "axios";
import SoftInput from "@/components/SoftInput.vue";
import OtpModal from "@/views/components/OTPmodal.vue";
import SoftButton from "@/components/SoftButton.vue";
import { mapMutations } from "vuex";
import Swal from 'sweetalert2';
import $ from 'jquery';
export default {
  name: "forgot_password",
  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
      isModalOpen:false,
      validation_fail: false,
      statusfail: false,
      token:""
    };
  },
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    OtpModal
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async login() {
      const formData = {
        email: $('#email').val(),
        password: $('#password').val(),
      };
      const loadingSpinner = Swal.fire({
          title: 'Please wait...',
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
      try
      {
        await axios
        .post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/vendor_sign_in`, formData)
        .then(response => {
          if (response.data.status == 200) {
            this.token = response.data.token;
            this.isModalOpen = true;
            
          } else if (response.data.status == 406) {
            Swal.fire({
            title: "Error!",
            text: "Account not yet activated.",
            icon: "error"
          });
          }
          else {
            Swal.fire({
            title: "Error!",
            text: "email and password did not match.",
            icon: "error"
          });
          }
        })
        .catch(error => {
          console.error("Backend error:", error);
        });
      }catch (error) {
          console.error('Backend error:', error);
          this.validation_fail = true;
        } finally {
          loadingSpinner.close();
        }
    },
    closeModal(){
      this.isModalOpen=false;
    }
  },
};
</script>
